<template>
    <div class="insights-portal-middle">
        <vs-row vs-type="grid" class="volume-grids">
            <div class="widget-container chart-widget">
                <div class="widget-title">Spend Breakdown by Category</div>
                <div class="widget-sub-title"></div>
                <BarChart 
                    :height="230"
                    :style="{width: '100%'}"
                    :chartData="spendBreakdownByCategoryData"
                    :chartOptions="spendBreakdownChartOptions"
                />
            </div>
            <div class="widget-container chart-widget">
                <div class="widget-title">Average Appointments per Month by Treatment type</div>
                <div class="widget-sub-title"></div>
                <BarChart 
                    :height="230"
                    :style="{width: '100%'}"
                    :chartData="avgAppointmentsByTreatmentTypeData"
                    :chartOptions="avgAppointmentsByTreatmentTypeOptions"
                />
            </div>
        </vs-row>
        <div class="divider-header">Market Share</div>
        <vs-row vs-type="grid" class="volume-grids">
            <div class="widget-container chart-widget">
                <div class="widget-title">Market Share - Tox</div>
                <div class="widget-sub-title"></div>
                <LineChart :height="300"  :chartData="toxShareData" :chartOptions="lineChartOptions" />
            </div>
            <div class="widget-container chart-widget">
                <div class="widget-title">Market Share - Filler</div>
                <div class="widget-sub-title"></div>
                <LineChart :height="300" chartId="fillerLineChart" :chartData="fillerShareData" :chartOptions="lineChartOptions" />
            </div>
            <div class="widget-container chart-widget">
                <div class="widget-title">Market Share - Others</div>
                <div class="widget-sub-title"></div>
                <LineChart :height="300" chartId="fillerLineChart" :chartData="otherShareData" :chartOptions="lineChartOptions" />
            </div>
        </vs-row>
    </div>
</template>
<script>
import LineChart from '../../components/insights/charts/LineChart.vue'
import BarChart from '../../components/insights/charts/BarChart.vue'
import { uniqBy } from "lodash";
export default {
    props: {
    },
    components: {
        LineChart,
        BarChart,
    },
    data() {
        return {
            spendBreakdownChartOptions:{
                interaction: {
                    intersect: false,
                        axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        onClick: null,
                        pointStyleWidth	: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            font: {
                                family: "InterMedium",
                                size: 12,
                            },
                            generateLabels: (chart) => {
                                const datasets = chart.data.datasets;
                                const uniqueDataset = uniqBy(datasets, 'label')
                                return uniqueDataset.map((data, i) => ({
                                    text: data.label,
                                    fillStyle: data.backgroundColor,
                                    lineWidth: 0
                                }))
                        }
                        },
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            title: function(data) {
                                if(data.length){
                                    const context = data[0]
                                    let label = context.label || '';

                                    if(context.dataset.stack) {
                                        label += ' ' +  context.dataset.stack
                                    }
                                    return label;
                                }
                                return '';
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ' + context.formattedValue;
                                }
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        display: false,
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            display: false,
                            color: "#5891AE",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        },
                        grid: {
                            display: false,
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        stacked: true,
                        title: {
                            display: false,
                        },
                        grid : {
                            display : false,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }   
            },
            avgAppointmentsByTreatmentTypeOptions:{
                interaction: {
                    intersect: false,
                        axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        onClick: null,
                        pointStyleWidth	: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            font: {
                                family: "InterMedium",
                                size: 12,
                            },
                            generateLabels: (chart) => {
                                const datasets = chart.data.datasets;
                                const uniqueDataset = uniqBy(datasets, 'label')
                                return uniqueDataset.map((data, i) => ({
                                    text: data.label,
                                    fillStyle: data.backgroundColor,
                                    lineWidth: 0
                                }))
                        }
                        },
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            title: function(data) {
                                if(data.length){
                                    const context = data[0]
                                    let label = context.label || '';

                                    if(context.dataset.stack) {
                                        label += ' ' +  context.dataset.stack
                                    }
                                    return label;
                                }
                                return '';
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ' + context.formattedValue;
                                }
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        display: false,
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            display: false,
                            color: "#5891AE",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        },
                        grid: {
                            display: false,
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid : {
                            display : false,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }   
            },
            spendBreakdownByCategoryData: {
                "labels": [
                    "Apr",
                    "May",
                    "Jun",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    "Jan",
                    "Feb",
                    "Mar"
                ],
                "datasets": [{
                    "stack": '2024',
                    "label": "Tox",
                    "data":[
                        5,
                        1.75,
                        1.86,
                        1.58,
                        1.92,
                        1.8,
                        2.05,
                        2.04,
                        1.77,
                        1.61,
                        1.85,
                        1.99
                    ],
                    "backgroundColor": "#5891AE"
                },{
                    "stack": '2024',
                    "label": "Filler",
                    "data":[ 1.8,
                        2.05,
                        2.04,
                        1.77,
                        1.86,
                        1.58,
                        1.61,
                        1.85,
                        1.99,
                        1.41,
                        1.75,
                        1.92,
                       
                    ],
                    "backgroundColor": "#45758E"
                },{
                    "stack": '2024',
                    "label": "Others",
                    "data":[ 
                        1.86,
                        1.58,
                        1.61,
                        1.85,
                        1.99,
                        1.41,
                        1.8,
                        2.05,
                        2.04,
                        1.77,
                        1.75,
                        1.92,
                       
                    ],
                    "backgroundColor": "#99E5DB"
                },{
                    "stack": '2025',
                    "label": "Tox",
                    "data":[
                        1.8,
                        2.05,
                        2.04,
                        1.77,
                        1.61,
                        1.85,
                        1.41,
                        1.75,
                        1.86,
                        1.58,
                        1.92,
                        1.99
                    ],
                    "backgroundColor": "#5891AE"
                },{
                    "stack": '2025',
                    "label": "Filler",
                    "data":[ 1.8,
                        2.05,
                        2.04,
                        1.99,
                        1.41,
                        1.75,
                        1.77,
                        1.86,
                        1.58,
                        1.61,
                        1.85,
                        1.92,
                       
                    ],
                    "backgroundColor": "#45758E"
                },{
                    "stack": '2025',
                    "label": "Others",
                    "data":[ 
                        1.8,
                        2.05,
                        2.04,
                        1.77,
                        1.75,
                        1.86,
                        1.58,
                        1.61,
                        1.85,
                        1.99,
                        1.41,
                        1.92,
                       
                    ],
                    "backgroundColor": "#99E5DB"
                }]
            },
            avgAppointmentsByTreatmentTypeData: {
                "labels": [
                    "Apr 2023",
                    "May 2023",
                    "Jun 2023",
                    "July 2023",
                    "Aug 2023",
                    "Sep 2023",
                    "Oct 2023",
                    "Nov 2023",
                    "Dec 2023",
                    "Jan 2024",
                    "Feb 2024",
                    "Mar 2024"
                ],
                "datasets": [{
                    "label": "Tox",
                    "data":[
                        22,
                        21,
                        49,
                        17,
                        22,
                        25,
                        28,
                        29,
                        22,
                        23,
                        21,
                        22
                    ],
                    "backgroundColor": "#5891AE"
                },{
                    "label": "Filler",
                    "data":[
                        49,
                        17,
                        22,
                        25,
                        28,
                        23,
                        22,
                        21,
                        21,
                        29,
                        22,
                        22
                    ],
                    "backgroundColor": "#45758E"
                },{
                    "label": "Others",
                    "data":[
                        22,
                        23,
                        22,
                        21,
                        49,
                        17,
                        29,
                        21,
                        22,
                        25,
                        28,
                        22
                    ],
                    "backgroundColor": "#99E5DB"
                }]
            },
            otherInjectablesData: {
                "labels": [
                    "Apr",
                    "May",
                    "Jun",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    "Jan",
                    "Feb",
                    "Mar"
                ],
                "datasets": [{
                    "label": "2023",
                    "data":[
                        0.22,
                        0.21,
                        0.33,
                        0.17,
                        0.22,
                        0.33,
                        0.28,
                        0.29,
                        0.22,
                        0.23,
                        0.21,
                        0.22
                    ],
                    "backgroundColor": "#5891AE"
                },{
                    "label": "2024",
                    "data":[
                        0.22,
                        0.23,
                        0.17,
                        0.22,
                        0.33,
                        0.21,
                        0.22,
                        0.22,
                        0.21,
                        0.33,
                        0.28,
                        0.29,
                    ],
                    "backgroundColor": "#04A4A6"
                }]
            },
            lineChartOptions: {
                interaction: {
                    intersect: false,
                        axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ' + Math.round(context.formattedValue);
                                }
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                            stepSize: 10,
                            callback: function(value, index, ticks) {
                                return value + '%';
                            }
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid : {
                            display : false,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            font: {
                                family: "Inter",
                                size: 12,
                            }
                        }
                    }
                }   
            },
            toxShareData: {
                "labels": [
                    "Apr",
                    "May",
                    "Jun",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    "Jan",
                    "Feb",
                    "Mar"
                ],
                "datasets": [{
                    "label": "Galderma",
                    "data":[
                        60,
                        58,
                        61,
                        55,
                        50,
                        48,
                        50,
                        57,
                        59,
                        45,
                        49,
                        50,
                    ],
                    "backgroundColor": "#5891AE",
                    "fill": true,
                    "borderColor": "#5891AE",
                    "pointStyle": 'circle',
                    "pointRadius": 0,
                    "pointBorderWidth": 3,
                    "pointHoverRadius": 8,
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderWidth": 3,
                },
                    {
                    "label": "Other Suppliers",
                    "data":[
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                    ],
                    "backgroundColor": "#D6D6D6",
                    "fill": true,
                    "borderColor": "#D6D6D6",
                    "pointStyle": 'circle',
                    "pointRadius": 0,
                    "pointBorderWidth": 3,
                    "pointHoverRadius": 8,
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderWidth": 3,
                }]
            },
            fillerShareData: {
                "labels": [
                    "Apr",
                    "May",
                    "Jun",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    "Jan",
                    "Feb",
                    "Mar"
                ],
                "datasets": [{
                    "label": "Galderma",
                    "data":[
                        44,
                        66,
                        45,
                        49,
                        50,
                        55,
                        58,
                        61,
                        77,
                        88,
                        76,
                        50,
                    ],
                    "backgroundColor": "#45758E",
                    "fill": true,
                    "borderColor": "#45758E",
                    "pointStyle": 'circle',
                    "pointRadius": 0,
                    "pointBorderWidth": 3,
                    "pointHoverRadius": 8,
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderWidth": 3,
                },
                    {
                    "label": "Other Suppliers",
                    "data":[
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                    ],
                    "backgroundColor": "#D6D6D6",
                    "fill": true,
                    "borderColor": "#D6D6D6",
                    "pointStyle": 'circle',
                    "pointRadius": 0,
                    "pointBorderWidth": 3,
                    "pointHoverRadius": 8,
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderWidth": 3,
                }]
            },
            otherShareData: {
                "labels": [
                    "Apr",
                    "May",
                    "Jun",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    "Jan",
                    "Feb",
                    "Mar"
                ],
                "datasets": [{
                    "label": "Galderma",
                    "data":[
                        55,
                        58,
                        61,
                        77,
                        74,
                        76,
                        50,
                        44,
                        66,
                        45,
                        49,
                        50,
                    ],
                    "backgroundColor": "#99E5DB",
                    "fill": true,
                    "borderColor": "#99E5DB",
                    "pointStyle": 'circle',
                    "pointRadius": 0,
                    "pointBorderWidth": 3,
                    "pointHoverRadius": 8,
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderWidth": 3,
                },
                    {
                    "label": "Other Suppliers",
                    "data":[
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                        100,
                    ],
                    "backgroundColor": "#D6D6D6",
                    "fill": true,
                    "borderColor": "#D6D6D6",
                    "pointStyle": 'circle',
                    "pointRadius": 0,
                    "pointBorderWidth": 3,
                    "pointHoverRadius": 8,
                    "pointHoverBackgroundColor": "#fff",
                    "pointHoverBorderWidth": 3,
                }]
            },
            lineChartCtx: 0,
        }
    },
    created() {
        
    }
}
</script>
<style>
.insights-portal-middle .divider-header {
    background-color: #5891AE;
    color: #F2F2F2;
    font-family: 'InterSemiBold';
    font-size: 16px;
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
}
.insights-portal-middle .volume-grids {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    margin-bottom: 12px;
}
.insights-portal-middle .volume-grids .chart-widget {
    display: block !important;
}
.insights-portal-middle .widget-sub-title {
    margin-bottom: 10px;
}
.insights-portal-middle .widget-title {
    font-family: 'InterSemiBold';
    font-size: 16px;
}
</style>