<template>
    <div class="insights-portal" :class="$route.name + '-page'">
        <div class="header">March 2024 Report | Galderma</div>
        <div class="insights-portal-content">
            <InsightsTop/>
            <InsightsMiddle/>
            <InsightsBottom  />
        </div>
    </div>
  </template>

<script>
import InsightsTop from './InsightsTop'
import InsightsMiddle from './InsightsMiddle'
import InsightsBottom from './InsightsBottom'

export default {
    components: {
        InsightsTop,
        InsightsMiddle,
        InsightsBottom
    },
    data() {
        return {
            };
    },
    computed: {
    },
    mounted() {
    },
    created() {
    },
};
</script>
<style>
.insights-portal .header {
    margin-top: 5px;
    padding-bottom: 10px;
    font-size: 22px;
    font-family: 'InterSemiBold';
}
.insights-portal .widget-container {
    position: relative;
    align-items: center;
    background: rgb(255, 255, 255);
    border-radius: 0.5rem;
    padding: 0.8rem 1rem;
    -webkit-box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
    margin-bottom:12px;
}
.insights-portal .chart-widget {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: none;
    border: 1px solid #F2F2F2;
}
</style>