<template>
    <el-table
        :data="tableData"
        style="width: 100%"
        default-expand-all
        row-key="id"
        height="600"
    >
      <el-table-column
        label-class-name="dysport-resty-first-header-label"
        fixed
        label=""
        prop="label"
        width="400"/>
        <el-table-column label-class-name="dysport-resty-top-header-label" label="Dysport & Resty" header-align="center">
            <el-table-column label-class-name="dysport-resty-header-label" label="Female" align="center">
                <el-table-column
                    prop="bracketA"
                    label="18-28"
                    header-align="center"
                    align="center"/>
                <el-table-column
                    prop="bracketB"
                    label="29-38"
                    header-align="center"
                    align="center"/>
                <el-table-column
                    prop="bracketC"
                    label="39-48"
                    header-align="center"
                    align="center"/>
                <el-table-column
                    prop="bracketD"
                    label="49-58"
                    header-align="center"
                    align="center"/>
                <el-table-column
                    prop="bracketE"
                    label="59-68"
                    header-align="center"
                    align="center"/>
                <el-table-column
                    prop="bracketF"
                    label="69-78" 
                    header-align="center"
                    align="center"/>
            </el-table-column>
        </el-table-column>
    </el-table>
  </template>
  
  <script>
    export default {
      data() {
        return {
          tableData: [{
            id: 1,
            label: '% of Total Patient base',
            bracketA: "30%",
            bracketB: "10%",
            bracketC: "10%",
            bracketD: "25%",
            bracketE: "15%",
            bracketF: "10%",
            children: [{
                    id: 80,
                    label: 'Tox Only',
                    bracketA: "30%",
                    bracketB: "10%",
                    bracketC: "10%",
                    bracketD: "25%",
                    bracketE: "15%",
                    bracketF: "10%",
                }, {
                    id: 81,
                    label: 'Filler Only',
                    bracketA: "30%",
                    bracketB: "10%",
                    bracketC: "10%",
                    bracketD: "25%",
                    bracketE: "15%",
                    bracketF: "10%",
                }, {
                    id: 82,
                    label: 'Tox and Filler',
                    bracketA: "30%",
                    bracketB: "10%",
                    bracketC: "10%",
                    bracketD: "25%",
                    bracketE: "15%",
                    bracketF: "10%",
                }, {
                    id: 83,
                    label: 'Skin booster',
                    bracketA: "30%",
                    bracketB: "10%",
                    bracketC: "10%",
                    bracketD: "25%",
                    bracketE: "15%",
                    bracketF: "10%",
                }]
          }, {
            id: 2,
            label: 'Dysport Share',
            bracketA: "50%",
            bracketB: "20%",
            bracketC: "10%",
            bracketD: "5%",
            bracketE: "5%",
            bracketF: "10%",
          }, {
            id: 3,
            label: 'Dysport Share  change last 12 months',
            bracketA: "20%",
            bracketB: "5%",
            bracketC: "50%",
            bracketD: "10%",
            bracketE: "5%",
            bracketF: "5%",
          }, {
            id: 4,
            label: 'Restylane Share',
            bracketA: "50%",
            bracketB: "20%",
            bracketC: "10%",
            bracketD: "5%",
            bracketE: "5%",
            bracketF: "10%",
          }, {
            id: 5,
            label: 'Restylane share change last 12 months',
            bracketA: "20%",
            bracketB: "5%",
            bracketC: "50%",
            bracketD: "10%",
            bracketE: "5%",
            bracketF: "5%",
          }, {
            id: 6,
            label: 'Average Treatment Areas per Appt',
            bracketA: 3.2,
            bracketB: 2,
            bracketC: 5.6,
            bracketD: 1.2,
            bracketE: 1,
            bracketF: 0,
          }, {
            id: 7,
            label: 'Average Treatment per year',
            bracketA: 4.1,
            bracketB: 4,
            bracketC: 4,
            bracketD: 3.5,
            bracketE: 0,
            bracketF: 0,
          }, {
            id: 8,
            label: 'Share of Treatment Areas by areas (Dysport):',
            children: [{
                    id: 21,
                    label: 'Crows',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                }, {
                    id: 22,
                    label: 'Glabella',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                },{
                    id: 23,
                    label: 'Frontalis',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                }, {
                    id: 24,
                    label: 'Feet',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                },{
                    id: 25,
                    label: 'Hands',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                }, {
                    id: 26,
                    label: 'Calves',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
            }]
          },{
            id: 11,
            label: 'Share of Treatment Areas by areas (Resty):',
            children: [{
                id: 31,
                label: 'Cheeks',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }, {
                id: 32,
                label: 'Lips',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            },{
                id: 33,
                label: 'Jawline',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }, {
                id: 34,
                label: 'Chin',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            },{
                id: 35,
                label: 'Neck',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }, {
                id: 36,
                label: 'Face',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }]
          },  {
            id: 14,
            label: 'Average dosage by treatment area (Dysport) Mls: ',
            children: [{
                    id: 41,
                    label: 'Crows',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                }, {
                    id: 42,
                    label: 'Glabella',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                },{
                    id: 43,
                    label: 'Frontalis',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                }, {
                    id: 44,
                    label: 'Feet',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                },{
                    id: 45,
                    label: 'Hands',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
                }, {
                    id: 46,
                    label: 'Calves',
                    bracketA: "50%",
                    bracketB: "20%",
                    bracketC: "10%",
                    bracketD: "5%",
                    bracketE: "5%",
                    bracketF: "10%",
            }]
          }, {
            id: 17,
            label: 'Average dosage by treatment area (Resty) Mls:',
            children: [{
                id: 51,
                label: 'Cheeks',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }, {
                id: 52,
                label: 'Lips',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            },{
                id: 53,
                label: 'Jawline',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }, {
                id: 54,
                label: 'Chin',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            },{
                id: 55,
                label: 'Neck',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }, {
                id: 56,
                label: 'Face',
                bracketA: "10%",
                bracketB: "5%",
                bracketC: "50%",
                bracketD: "20%",
                bracketE: "5%",
                bracketF: "10%",
            }]
          }]
        }
      },
    }
  </script>
<style>
.dysport-resty-first-header-label{
    background-color: #fff !important;
}
.dysport-resty-top-header-label {
    background-color: #5891AE !important;
    border-bottom: 1px solid #5891AE !important;
    color: #fff;
    font-family: 'InterSemiBold';
    font-size: 16px;
}
.dysport-resty-header-label {
    background-color: #45758E !important;
    color: #fff;
    font-family: 'InterSemiBold';
    font-size: 16px;
}
</style>