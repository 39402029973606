<template>
    <div class="insights-portal-bottom">
        <vs-row>
            <div class="widget-container chart-widget">
                <div class="widget-title">Brand Loyalty</div>
                <div class="widget-sub-title"></div>
                <InsightsBrandLoyalty/>
            </div>
        </vs-row>
        <vs-row>
            <div class="widget-container chart-widget">
                <div class="widget-title"></div>
                <div class="widget-sub-title"></div>
                <InsightsDysportAndResty/>
            </div>
        </vs-row>
    </div>
</template>
<script>
import BarChart from '../../components/insights/charts/BarChart.vue'
import InsightsBrandLoyalty from './InsightsBrandLoyalty.vue'
import InsightsDysportAndResty from './InsightsDysportAndResty.vue'

export default {
    props: {
    },
    components: {
        BarChart,
        InsightsBrandLoyalty,
        InsightsDysportAndResty
    },
    data() {
        return {
            barChartOptions:{
                interaction: {
                    intersect: false,
                        axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        pointStyleWidth	: 14,
                        boxHeight: 10,
                        display: false,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#5891AE",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function(context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function(context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function(context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ' + Math.round(context.formattedValue);
                                }
                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                            stepSize: 50
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid : {
                            display : false,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }   
            },
            treatmentVolumeData: {
                "labels": [
                    "Apr",
                    "May",
                    "Jun",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                    "Jan",
                    "Feb",
                    "Mar"
                ],
                "datasets": [{
                    "label": "Treatment Volume",
                    "data":[
                        70,
                        80,
                        90,
                        110,
                        120,
                        115,
                        118,
                        130,
                        125,
                        75,
                        120,
                        122
                    ],
                    "backgroundColor": "#5891AE"
                }]
            }
        }
    },
}
</script>
<style>
.insights-portal-bottom .content-container {
    position: relative;
    align-items: center;
    background: rgb(255, 255, 255);
    border-radius: 0.5rem;
    padding: 0.8rem 1rem;
    margin-bottom:12px;
    border: 1px solid #F2F2F2;
    width: 100%;
}
.insights-portal-bottom .content-container .content-title {
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 5px;
}
.insights-portal-bottom .content-container .content-body li {
    margin-bottom: 5px;
} 
.insights-portal-bottom .chart-widget {
    display: block !important;
}
.insights-portal-bottom .widget-title {
    font-family: 'InterSemiBold';
    font-size: 16px;
}
</style>