<template>
    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      style="width: 100%">
      <el-table-column
        fixed
        label=""
        prop="shareLabel"
        width="170"/>
      <el-table-column
        prop="spendLabel"
        label="Spend"
        width="350"/>
      <el-table-column
        prop="overall"
        label="Overall"
        width="120"/>
        
      <el-table-column
          prop="bracketA"
          label="0%"/>
      <el-table-column
          prop="bracketB"
          label="1-20%"/>
      <el-table-column
          prop="bracketC"
          label="21-40%"/>
      <el-table-column
          prop="bracketD"
          label="41-60%"/>
      <el-table-column
          prop="bracketE"
          label="61-80%"/>
      <el-table-column
          prop="bracketF"
          label="81-100%"/>
    </el-table>
  </template>
  
  <script>
    export default {
      data() {
        return {
          tableData: [{
            shareLabel: 'Dysport % Share',
            spendLabel: 'Clinics Spending <$50k on Tox per year',
            overall: 215,
            bracketA: 15,
            bracketB: 20,
            bracketC: 30,
            bracketD: 40,
            bracketE: 50,
            bracketF: 60
          }, {
            shareLabel: 'Dysport % Share',
            spendLabel: 'Clinics Spending $50-100k on Tox per year',
            overall: 1350,
            bracketA: 100,
            bracketB: 150,
            bracketC: 200,
            bracketD: 250,
            bracketE: 300,
            bracketF: 350,
          }, {
            shareLabel: 'Dysport % Share',
            spendLabel: 'Clinics Spending >$100k on Tox per year',
            overall: 880,
            bracketA: 75,
            bracketB: 125,
            bracketC: 150,
            bracketD: 160,
            bracketE: 180,
            bracketF: 190,
          }, {
            shareLabel: 'Restylane % Share',
            spendLabel: 'Clinics Spending <$20k on Filler per year',
            overall: 215,
            bracketA: 15,
            bracketB: 20,
            bracketC: 30,
            bracketD: 40,
            bracketE: 50,
            bracketF: 60,
          },  {
            shareLabel: 'Restylane % Share',
            spendLabel: 'Clinics Spending $20-50k on Tox per year',
            overall: 1350,
            bracketA: 100,
            bracketB: 150,
            bracketC: 200,
            bracketD: 250,
            bracketE: 300,
            bracketF: 350,
          }, {
            shareLabel: 'Restylane % Share',
            spendLabel: 'Clinics Spending >$50k on Tox per year',
            overall: 880,
            bracketA: 75,
            bracketB: 125,
            bracketC: 150,
            bracketD: 160,
            bracketE: 180,
            bracketF: 190,
          }]
        }
      },
      methods: {
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
            if (rowIndex % 3 === 0) {
                return {
                rowspan: 3,
                colspan: 1
                };
            } else {
                return {
                rowspan: 0,
                colspan: 0
                };
            }
            }
        }
      }
    }
  </script>