<template>
    <div class="insights-portal-top">
        <vs-row vs-type="grid" class="current-number-grids">
            <div class="widget-container chart-widget">
                <div class="widget-title monthly text-center">Tox Sales ($ Mar 24)</div>
                <div class="widget-count text-center">$1,985,247</div>
            </div>
            <div class="widget-container chart-widget">
                <div class="widget-title monthly text-center">Filler Sales ($ Mar 24)</div>
                <div class="widget-count text-center">$224,924</div>
            </div>
            <div class="widget-container chart-widget">
                <div class="widget-title yearly text-center">Tox Sales (2024 cal year)</div>
                <div class="widget-count text-center">$5,453,469</div>
            </div>
            <div class="widget-container chart-widget">
                <div class="widget-title yearly text-center">Filler Sales (2024 cal year)</div>
                <div class="widget-count text-center">$668,931</div>
            </div>
        </vs-row>
        <vs-row vs-type="grid" class="current-number-grids">
            <div class="widget-container chart-widget">
                <div class="widget-title card-title text-center"># of new accounts</div>
                <div class="widget-count text-center">72</div>
            </div>
            <div class="widget-container chart-widget">
                <div class="widget-title card-title text-center"># of accounts where share has dropped by >20% in the last 3 months</div>
                <div class="widget-count text-center">44</div>
            </div>
        </vs-row>
        <!-- <vs-row>
            <div class="content-container">
                <div class="content-title">Galderma Performance</div>
                <div class="content-body">
                    <ul >
                        <li>• Dysport sales were up 8% (vs. Feb 2024), with the overall Tox market volume also up 8% in March, resulting
                        in Galderma holding 59% market share, flat MoM.</li>
                        <li>• Restylane sales were up 5% (vs. Feb 2024), with the overall Filler market up 6% in March, resulting in
                        Galderma holding 21% market share, which is flat MoM.</li>
                    </ul>
                </div>
            </div>
        </vs-row> -->
    </div>
</template>
<script>
export default {
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>
<style>
.insights-portal-top .content-container {
    position: relative;
    align-items: center;
    background: rgb(255, 255, 255);
    border-radius: 0.5rem;
    padding: 0.8rem 1rem;
    margin-bottom:12px;
    border: 1px solid #F2F2F2;
    width: 100%;
}
.insights-portal-top .content-container .content-title {
    font-size: 15px;
    font-weight: 800;
    margin-bottom: 5px;
}
.insights-portal-top .content-container .content-body li {
    margin-bottom: 5px;
} 
.insights-portal-top .current-number-grids {
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    margin-bottom: 12px;
}
.insights-portal-top .widget-container {
    padding: 0 !important;
}

.insights-portal-top .widget-count {
    font-size: 18px;
    padding: 15px 5px 10px;
}
.insights-portal-top .widget-title {
    font-size: 14px;
    font-family: 'InterSemiBold';
    width: 100%;
    border-radius: 0.5rem 0.5rem 0 0 ;
    padding: 10px 5px;
    color: #000;
}
.insights-portal-top .widget-title.monthly {
    background-color: #7F7F7F;
    color: #fff;
}
.insights-portal-top .widget-title.yearly {
    background-color: #5891AE;
    color: #fff;
}
.insights-portal-top .widget-title.card-title {
    background-color: #5891AE;
    color: #fff;
}
</style>